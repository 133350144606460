import { FC, ReactNode } from "react";
import Head from "next/head";
import { Header } from "@components/Layout/Header/Header";
import { BodyLayout } from "@components/Layout/BodyLayout";
import { Footer } from "@components/Layout/Footer";
import Script from "next/script";
import { User } from "@prisma/client";

interface Props {
  currentUser?: User;
  pageTitle?: string;
  children?: ReactNode;
  bodyCN?: string;
}

export const Layout: FC<Props> = ({
  currentUser,
  pageTitle,
  children,
  bodyCN,
}) => {
  return (
    <>
      <Head>
        <title>{pageTitle} | Hero Messenger</title>
        <link rel="icon" href="/favicon.svg" />
      </Head>

      <main className="min-h-screen bg-gray-50">
        <div className="fixed bg-gray-50 border-b top-0 left-0 z-20 w-full h-16 sm:static sm:border-none sm:bg-transparent">
          <Header user={currentUser} />
        </div>
        <div className="pt-16 sm:pt-0">
          <BodyLayout cN={bodyCN}>{children}</BodyLayout>
        </div>
        <Footer />
      </main>

      {/* Global site tag (gtag.js) - Google Analytics */}
      <>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
        `}
        </Script>
      </>
    </>
  );
};
