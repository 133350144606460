import { FC } from "react";
import Link from "next/link";
import LogoSvg from "@public/favicon.svg";
import { classNames } from "@utils/classNames";

interface Props {
  logoCN?: string;
}

export const Logo: FC<Props> = ({ logoCN }) => (
  <Link href="/">
    <a className="flex-shrink-0 flex items-center">
      <LogoSvg className={classNames("block fill-current", logoCN)} />
      {/* <span
        className={classNames(
          "pt-0.5 block font-extrabold text-2xl text-blue-600 italic tracking-tight"
        )}
      >
        HERO
      </span> */}
    </a>
  </Link>
);
