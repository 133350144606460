import React, { FC } from "react";
import Link from "next/link";

const FooterLink = ({ href, children }) => (
  <Link href={href}>
    <a className="block text-gray-400 hover:text-gray-500">{children}</a>
  </Link>
);

export const Footer: FC = () => (
  <div className="mt-20 pt-10 pb-20 border-t">
    <div className="max-w-3xl mx-auto">
      <div className="flex flex-row justify-center mx-auto my-4 space-x-4 text-xs text-gray-300 ">
        &copy; 2022 heromsg.com. All Rights Reserved.
      </div>
    </div>
  </div>
);
