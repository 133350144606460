import React, { FC } from "react";
import { classNames } from "@utils/classNames";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import Image from "next/image";

interface Props {
  filename?: string;
  alt?: string;
  width: number;
  height: number;
  cN?: string;
}

export const UserImage: FC<Props> = ({
  filename,
  alt = "Profile image",
  width,
  height,
  cN = "text-gray-400",
}) => {
  return filename ? (
    <Image
      src={`https://hero-msg.imgix.net/users/images/${filename}?w=${width}&h=${height}&fit=crop`}
      width={width}
      height={height}
      alt={alt}
      placeholder="empty"
      className={classNames("rounded-full", cN)}
    />
  ) : (
    <UserCircleIcon className={cN} width={width} height={height} />
  );
};
