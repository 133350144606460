import { Menu, Transition } from "@headlessui/react";
import { FC, forwardRef, Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "@utils/classNames";
import Link from "next/link";
import { UserImage } from "@components/UserImage/UserImage";
import { User } from "@prisma/client";

interface Props {
  user: User;
}

const MenuButton = ({ user }: { user: User }) => (
  <Menu.Button className="flex text-sm focus:outline-none">
    <div className="flex items-center h-12 -mr-2 px-2 rounded hover:bg-gray-200 sm:h-10">
      <UserImage filename={user?.image} width={28} height={28} />
      <ChevronDownIcon className="block h-4 w-4 text-black" />
    </div>
  </Menu.Button>
);

// Note: The Next.js Link component does not forward unknown props to the underlying a element, so it won't close the menu on click when used inside a Menu.Item.
// To use a Next.js Link inside a Menu.Item, create your own component that wraps Link and forwards unknown props to the child a element.
// https://headlessui.dev/react/menu#integrating-with-next-js
const CustomLink = forwardRef((props: any, ref) => {
  let { href, children, cN = "", ...rest } = props;
  return (
    <Link href={href}>
      <a
        ref={ref}
        className={classNames(
          "block px-4 py-2 text-gray-700 hover:bg-gray-100",
          cN
        )}
        {...rest}
      >
        {children}
      </a>
    </Link>
  );
});

export const SecondaryNavigation: FC<Props> = ({ user }) => {
  return (
    <Menu as="div" className="ml-2 relative z-20">
      {({ open }) => (
        <>
          <MenuButton user={user} />
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right fixed right-0 mt-2 left-0 text-lg tracking-tight shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none sm:absolute sm:left-auto sm:w-64 sm:text-sm sm:rounded-md"
            >
              {user ? (
                <>
                  <div className="px-4 py-3">
                    <p className="">Logged in as</p>
                    <p
                      className="font-medium text-xs text-gray-900 truncate"
                      title={user.email}
                    >
                      {user.email}
                    </p>
                  </div>
                  <div className="border-b"></div>
                  <Menu.Item>
                    <CustomLink href={`/${user.handle}`}>Profile</CustomLink>
                  </Menu.Item>
                  <div className="border-b"></div>
                  {/* <CustomLink href="/user">Dashboard</CustomLink> */}
                  <Menu.Item>
                    <CustomLink href="/user/inbox">Inbox</CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink href="/user/sent">Sent</CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink href="/user/topics">Topics</CustomLink>
                  </Menu.Item>
                  <div className="border-b"></div>
                  <Menu.Item>
                    <CustomLink href="/user/wallet">Wallet</CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink href="/user/profile">Edit profile</CustomLink>
                  </Menu.Item>
                  {/* <CustomLink href="/user/payments">Moje platby</CustomLink> */}
                  <div className="border-b"></div>
                </>
              ) : (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/api/auth/login">
                        <a className="block px-4 py-2 font-bold text-gray-700 hover:bg-gray-100">
                          Sign up
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/api/auth/login">
                        <a className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                          Log in
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                  <div className="border-b"></div>
                </>
              )}
              {/* Only display on small screens (where primary menu is hidden) */}
              {/* <div className="block sm:hidden">
                <CustomLink href="/">Find work</CustomLink>
                <CustomLink href="/hiring">Hire devs</CustomLink>
                <CustomLink href="/job-posts/create" cN="font-bold">
                  Post a job
                </CustomLink>
                <div className="border-b"></div>
              </div> */}
              {/* <CustomLink href="/about">About Jobs in JS</CustomLink> */}

              {user ? (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/api/auth/logout">
                        <a className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                          Log out
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                </>
              ) : null}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
