import { FC } from "react";
import { Logo } from "./Logo";
import { PrimaryNavigation } from "./PrimaryNavigation";
import { SecondaryNavigation } from "./SecondaryNavigation";
import { User } from "@prisma/client";

interface Props {
  user: User;
}

export const Header: FC<Props> = ({ user }) => {
  return (
    <div className="flex justify-between h-16 items-center px-4 sm:px-4">
      {/* Left side */}
      <div className="flex">
        <Logo logoCN="h-10 text-black hover:text-red-600" />
        {/* <PrimaryNavigation /> */}
      </div>

      {/* Right side */}
      <div className="flex">
        <SecondaryNavigation user={user} />
      </div>
    </div>
  );
};
